$ERROR_RED: #ff6e6e;
$GREEN_COLOR: #3fd184;
$MAIN_COLOR: #bdb08f;
$GRAY_COLOR: #c2c2c2;
$CHART_START_COLOR: #333333;
$MODAL_BACKGROUND: #333333;

$themes: (
  light: (
    color-background: #ffffff,
    color-main: #bdb08f,
    color-main-darker: #887649,
    color-gray: #c2c2c2,
    color-success: #3fd184,
    color-error: #ff6e6e,
    color-text: #333333,
    border-main: 1px solid #bdb08f,
    border-button: 2px solid #333333,
    border-disabled: 1px solid #c2c2c2,
    filter-invert: invert(0%),
    shadow: 0 5px 20px 0 rgba(189, 176, 143, 0.24),
    shadow-side-bar: 4px 5px 11px 0 rgba(189, 176, 143, 0.24),
    last-transaction-linear-gradient:
      linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.6),
        #ffffff,
        #ffffff
      ),
    main-linear-gradient: linear-gradient(#cfbe92, #bdb08f)
  ),
  dark: (
    color-background: #2d2c29,
    color-main: #bdb08f,
    color-main-darker: #887649,
    color-gray: #c2c2c2,
    color-success: #3fd184,
    color-error: #ff6e6e,
    color-text: #ffffff,
    border-main: 1px solid #bdb08f,
    border-button: 2px solid #ffffff,
    border-disabled: 1px solid #c2c2c2,
    filter-invert: invert(100%),
    shadow: 0 5px 20px 0 rgba(189, 176, 143, 0.24),
    shadow-side-bar: 4px 5px 11px 0 rgba(189, 176, 143, 0.24),
    last-transaction-linear-gradient:
      linear-gradient(
        rgba(45, 44, 41, 0.3),
        rgba(45, 44, 41, 0.6),
        #2d2c29,
        #2d2c29
      ),
    main-linear-gradient: linear-gradient(#cfbe92, #bdb08f)
  )
);

:export {
  chart_start_color: $CHART_START_COLOR;
  gray_color: $GRAY_COLOR;
  main_color: $MAIN_COLOR;
  success_color: $GREEN_COLOR;
  error_color: $ERROR_RED;
  modal_background: $MODAL_BACKGROUND;
}
